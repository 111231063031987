<template>
  <div>
    <div v-if="images != ''">
      <div class="row">
        <div class="image-col"
             :class="colSize"
             v-for="(image, index) in filteredImages"
             :key="index">

          <div class="content-wrapper text-center">
            <div class="overlay-actions">
              <!-- Share Feature -->
              <!-- <div class="overlay-share"
                   v-if="shareFeature === true">
                <i class="fas fa-share fa-2x share-icon"
                   @click="showShareDialog(image)">
                </i>
                <br />
              </div> -->

              <!-- Delete Feature -->
              <div class="overlay-delete"
                   v-if="deleteFeature === true">
                <i class="fas fa-trash-alt delete-icon"
                   @click="deleteImage(image)">
                </i>
                <br />
              </div>

              <!-- Report Feature -->
              <div class="overlay-report"
                   v-if="reportFeature === true">
                <i class="fas fa-flag report-icon"
                   @click="reportImage(image)"
                   title="Report Image">
                </i>
                <br />
              </div>

              <!-- Download Feature -->
              <div class="overlay-download">
                  <i class="fas fa-download fa-2x download-icon"
                     @click="downloadImage(image)"></i>
              </div>
            </div>
            
            <!-- Image -->
            <img class="image img-fluid"
                 :class="border ? 'image-border' : '' "
                 :src="image.url"
                 alt="image"
                 :style="{height: `${imgHeight}px`}"
                 :key="image.id"
                 @click="showLightbox(image.name)"/>

            <!-- User Image -->
            <div class="overlay-user-image"
                 v-if="userImage === true">
              <p class="user-name mb-0">
                <img :src="image.userImage"
                     alt="user-profile"
                     v-if="image.userImage" />
                <img src="../../../assets/images/sample_student_profile.png"
                     alt="user-image"
                     v-else />
                {{ image.userNickname }}
              </p>
            </div>
          </div>
        </div>

        <lightbox id="mylightbox"
                  ref="lightbox"
                  :directory="thumbnailDir"
                  :filter="galleryFilter"
                  :images="filteredImages"
                  :timeoutDuration="5000">
        </lightbox>
      </div>

      <slot name="load"></slot>
    </div>

    <!-- Empty -->
    <div class="row" v-else>
      <div class="col" v-if="!downloading">
        <p class="default-text">No images available</p>
      </div>
    </div>

    <!-- Modal -->
    <modals-container/>

    <!-- Dialog -->
    <v-dialog />
  </div>
</template>

<script>
  const ReportContent = () => import('@/components/modals/ReportContent.vue');
  import { mapGetters } from 'vuex';
  
  // const ShareContent = () => import('@/components/modals/ShareContent.vue');
  // import { mapActions, mapGetters } from 'vuex';

  export default {
    name  : 'ImageGallery',
    props : {
      border        : Boolean,
      colImage      : Number,
      deleteFeature : Boolean,
      images        : Array,
      reportFeature : Boolean,
      shareFeature  : Boolean,
      userImage     : Boolean,
      downloading   : Boolean,
    },
    data() {
      return {
        thumbnailDir  : process.env.VUE_APP_CLOUDFRONT_BASE_URL,
        galleryFilter : 'all',
        colWidth      : 0,
        imgHeight     : 0,
      };
    },
    updated() {
      this.getColumnWidth();
    },
    mounted() {
      this.getColumnWidth();

      // Get Classrooms
      // this.getUserClassrooms();
    },
    computed : {
      ...mapGetters({
        user : 'user/user',

        // userClassrooms : 'classroom/userClassrooms',
      }),
      filteredImages : function () {
        var result = [];

        if (this.images) {
          for (const element of this.images) {
            result.push({
              name : element.item.path + element.item.filename,
              id   : (element.item.ucc_id) ?
                element.item.ucc_id : element.item.id,
              filter       : 'all',
              url          : element.item.url,
              userImage    : element.item.profile_img_url,
              userNickname : element.item.nickname,
              filename     : element.item.filename,
              userId       : element.item.user_id,
              guardianId   : element.item.guardian_id,
            });
          }

          return result;
        }

        return null;
      },
      colSize() {
        return 'col-' + this.colImage;
      },
    },
    watch : {
      colWidth : function () {
        this.imgHeight = this.colWidth > 0 ? this.colWidth * 0.75 : 0;
      },
    },
    methods : {

      // ...mapActions({
      //   'getUserClassrooms' : 'classroom/getUserClassrooms',
      // }),

      /**
       * To view image's whole photo
       *
       * @param imageName Image name
       */
      showLightbox : function (imageName) {
        this.$refs.lightbox.show(imageName);
      },

      /**
       * Share Dialog
       * @param image Image's data
       */
      // showShareDialog(image) {
      //   if (this.userClassrooms) {
      //     this.$modal.show(ShareContent, {
      //       mediaData : image,
      //       type      : 'image',
      //     }, {
      //       height   : 'auto',
      //       adaptive : true,
      //     });
      //   } else {
      //     this.$notify({
      //       group : 'notif',
      //       type  : 'error',
      //       text  : 'Unable to share image. Please join any classroom first.',
      //     });
      //   }
      // },

      /**
       * Delete Image
       *
       * @param image Image data
       */
      deleteImage(image) {
        this.$modal.show('dialog', {
          title   : 'Delete Image',
          text    : 'Are you sure you want to delete this image? <br> ',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$emit('loadingchange', true);
              this.$http.delete('api/media/ucc/delete', {
                data : {
                  'userId' : this.user.user_id,
                  'id'     : image.id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Success',
                  type  : 'success',
                  text  : 'Deleted image successfully!',
                });
                this.$emit('update-user-contents');
                this.$emit('loadingchange', false);
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Failed',
                  type  : 'error',
                  text  : 'OOPS! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Report image
       * @param image
       */
      reportImage(image) {
        this.$modal.show(ReportContent, {
          mediaData : image,
          type      : 'image',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('image-col')[0];
          if ($colId)
            this.colWidth = $colId.clientWidth;
        });
      },

      /**
       * Download Image
       */
      downloadImage(image) {
        this.$emit('loadingchange', true);
        this.$http.get('/api/ucc/downloadUrl', {
          params : {
            uccId : image.id,
          },
        }).then((res) => {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", res.data.url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(xhr.response);
            document.body.appendChild(a);
            a.download = image.filename;
            a.href = url;
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            this.$emit('loadingchange', false);
          };
          xhr.send();
        })
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/common/image-gallery";
</style>
